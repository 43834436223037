import type { Properties } from '@playful/runtime';
import { proxy } from 'comlink';

import { connectSandboxToHost } from './bridge';
import { loadPlugins } from './plugins';
import type { ActionEvent, RemoteClientApi } from './types';

declare global {
  interface NodeModule {
    hot: any;
  }

  interface Window {
    play: RemoteClientApi;
    properties: Properties;
  }
}

let properties: Properties = {};
let ready = false;

const { bridgeId, debug, plugins } = JSON.parse(
  document.head.querySelector('[type="text/play-config"]')?.textContent ?? '{}',
);

const playWrapper: ProxyHandler<RemoteClientApi> = {
  get(target, prop, receiver) {
    if (prop === 'properties') {
      return properties;
    }

    if (prop === 'isReady') {
      return ready;
    }

    const result = (target as any)[prop];

    // FIXME: this is a hack in service of the play.init experiment. One of the
    // first things in phase 2 will be to plumb this right
    if (prop === 'init') {
      return ({
        action,
        command,
        update,
      }: {
        action: (event: ActionEvent) => void;
        command: (command: string) => void;
        update: (props: Properties) => void;
      }) => {
        result(
          action ? proxy(action) : undefined,
          command ? proxy(command) : undefined,
          update ? proxy(update) : undefined,
        );
      };
    }

    // we get away with this because EVERYTHING from comlink is a proxy function
    return (...args: any[]) =>
      result(...args.map((arg) => (typeof arg === 'function' ? proxy(arg) : arg)));
  },
};

const play = connectSandboxToHost(self, bridgeId);

if (debug) {
  play.log('Connected', bridgeId);
}

function awaitBridgeReady(): Promise<void> {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(reject, 1000);

    play.onUpdate(
      proxy((props: Properties) => {
        properties = {
          ...properties,
          ...props,
        };

        clearTimeout(timeout);

        if (!ready) {
          ready = true;
          resolve();
        }
      }),
    );
  });
}

// prettier-ignore
Promise.all([
  loadPlugins(plugins).then(() => {
    debug && play.log('plugins loaded');
  }),
  awaitBridgeReady().then(() => {
    debug && play.log('bridge ready');
  }),
]).then(() => {
  debug && play.log('play ready');
  play.notifyReady();
});

window.play = new Proxy(play, playWrapper) as RemoteClientApi;
