/**
 * Sandbox Plugins
 *
 * The sandbox needs to be able to support certain functionalities which aren't
 * needed all the time. A great example of this is that during design time, the
 * sandbox (iframe) based components need to be able to participate in generation
 * of thumbnails by self thumbnailing and sending it out to to play. But this
 * functionality is not needed when playing the project.
 *
 * To keep the sandboxClient payload as small and light as possible, these extra
 * intermittently needed functionality are packaged as separate webpack chunks
 * and each component can individually determine which it needs.
 *
 * Each plugin takes the form of a chunk that exports a function of the same
 * name which takes the play object as a parameter. So
 *
 * // myPlugin.tsx:
 * import type { RemoteClientApi } from '../types';
 *
 * export async function myPlugin(play: RemoteClientApi) {
 *   play.onUpdate(() => {
 *     console.log("updated!")
 *   });
 * }
 */

/**
 * plugin registry:
 *
 * embedInMainBinary: () => require('./embedInMainBinary'),
 * asyncLoadAsPlugin: () => import(/* webpackChunkName=pluginOrPluginBundleName *​/ './asyncLoadAsPlugin'),
 */
const plugins: Record<string, any> = {
  eventForwarder: () => require('./eventForwarder'),
  thumbnailer: () => import(/* webpackChunkName=thumbnailer */ './thumbnailer'),
};

const notFound = () => Promise.reject();

async function getPlugin(plugin: string) {
  return (plugins[plugin] ?? notFound)();
}

// enable selected plugins
export function loadPlugins(plugins: string[]): Promise<void[] | void> {
  if (!plugins || !plugins.length) {
    return Promise.resolve();
  }

  return Promise.all(
    plugins.map(async (it) => {
      try {
        const plugin = await getPlugin(it);
        const includeHook = plugin[it];
        if (!includeHook) {
          console.error(`can't enable plugin: ${it}`);
        }
        includeHook(window.play);
      } catch (e) {
        console.error(`can't find plugin: ${it}`);
        return;
      }
    }),
  );
}
